import { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import useAuth from '../../auth/useAuth';
import { useSoundStatus } from '../../context/SoundStatusContext';

import Layout from '../UI/introGameLayout/layout';

import BackgroundImage from './images/game-background-blurred.jpg';

import { 
  getGamesSessions,
  scenarioLevelsQuery,
  getDecisions,
  makeDecision,
  updateDecision,
  gamesQuery
} from './api';

import Modal from '../UI/modal';
import BalanceSheet from '../UI/balancedEquation/balanceSheet';

import TransactionRecords from '../UI/balancedEquation/transactionRecords';

import './styles.css';

import GameProcessSound from './sounds/game-process.mp3';
import ClickSound from './sounds/click.mp3';
import AnswerCorrectSound from './sounds/answer-correct.mp3';
import AnswerWrongSound from './sounds/answer-wrong.mp3';

import SignMinus from './images/icon-minus.svg';
import SignPlus from './images/icon-plus.svg';

export default function BalancedEquation() {
  const gameProcessEffect = useRef(new Audio(GameProcessSound));
  const clickEffect = useRef(new Audio(ClickSound));
  const answerCorrectEffect = useRef(new Audio(AnswerCorrectSound));
  const answerWrongEffect = useRef(new Audio(AnswerWrongSound));

  const navigate = useNavigate();

  const params = useParams();
  const levelNumber = Number(params.levelNumber);

  const { 
    getUser,
    getGuidedPlay
  } = useAuth();

  const user = getUser();

  const { soundStatus } = useSoundStatus();

  const shouldGuidedPagesBeDisplayed = getGuidedPlay();

  const [currentGameId, setCurrentGameId] = useState(0);
  const [scenarioId, setScenarioId] = useState(0);

  const [questions, setQuestions] = useState([]);
  const [activeQuestionId, setActiveQuestionId] = useState(0);
  const [questionAnsweredCorrectly, setQuestionAnsweredCorrectly] = useState(0);

  const [topOptions, setTopOptions] = useState([]);
  const [changeTypeOptions, setChangeTypeOptions] = useState([]);
  const [coreTypeOptions, setCoreTypeOptions] = useState([]);
  const [transactionTypeOptions, setTransactionTypeOptions] = useState([]);

  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    const playClickSound = () => {
      if (soundStatus === 'enabled') {
        clickEffect.current.play().catch(e => console.error('Error playing click sound:', e));
      }
    };

    document.addEventListener('click', playClickSound);

    return () => {
      document.removeEventListener('click', playClickSound);
    };
  }, [soundStatus]);

  /* Prevent sound from playing on other pages */
  useEffect(() => {
    const currentGameProcessEffect = gameProcessEffect.current;
  
    return () => {
      if (currentGameProcessEffect) {
        currentGameProcessEffect.pause();
      }
    };
  }, [location]);

  /* play background music */
  useEffect(() => {  
    if (isPlaying && soundStatus === 'enabled') {
      gameProcessEffect.current.volume = 0.09;
      gameProcessEffect.current.loop = true;
      gameProcessEffect.current.play().catch(e => console.log('Error playing game process sound:', e));
    } else {
      gameProcessEffect.current.pause();
    }
  
    return () => {
      gameProcessEffect.current.pause();
    };
  }, [isPlaying, soundStatus]);

  const [totalScore, setTotalScore] = useState(0);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [assets, setAssets] = useState(0);
  const [liabilities, setLiabilities] = useState(0);
  const [equity, setEquity] = useState(0);

  const [assetsChildren, setAssetsChildren] = useState([]);
  const [liabilitiesChildren, setLiabilitiesChildren] = useState([]);
  const [equityChildren, setEquityChildren] = useState([]);

  const resetBalanceSheet = () => {
    setAssets(0);
    setLiabilities(0);
    setEquity(0);
    

    assetsChildrenRef.current = [];
    liabilitiesChildrenRef.current = [];
    equityChildrenRef.current = [];
    setAssetsChildren([]);
    setLiabilitiesChildren([]);
    setEquityChildren([]);
  }

  const assetsChildrenRef = useRef(assetsChildren);
  const liabilitiesChildrenRef = useRef(liabilitiesChildren);
  const equityChildrenRef = useRef(equityChildren);

  const [time, setTime] = useState(0);
  const timeRef = useRef(time);
  useEffect(() => {
    timeRef.current = time;
  }, [time]);

  useEffect(() => {
    if (!isPlaying) return;
    const timerInterval = setInterval(() => {
      const newTime = timeRef.current + 1;
      setTime(newTime);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [isPlaying]);

  const [answerStatus, setAnswerStatus] = useState(null);
  const [isExplanationVisible, setIsExplanationVisible] = useState(false);
  const [explanationContent, setExplanationContent] = useState(null);

  const [timeFromAppear, setTimeFromAppear] = useState(0);

  const [isSubmitButtonShown, setIsSubmitButtonShown] = useState(true);

  const { data: games } = useQuery(gamesQuery()) || {};

  useEffect(() => {
    if(games) {
      const game = games.find(game => game.game_type.includes('Balanced Equation'));

      if(game && game.scenario) {
        setCurrentGameId(game.id);
        setScenarioId(game.scenario);
      }
    }
  }, [games]);

  const { data: decisionsResponse, isSuccess: isDecisionsSuccess } = useQuery({
    queryKey: [`decisionsResponse_${levelNumber}`, { userId: user?.id, gameId: currentGameId, level: levelNumber }],
    queryFn: () => getDecisions({ userId: user?.id, gameId: currentGameId, level: levelNumber }),
    enabled: !!user?.id && !!currentGameId,
    refetchOnWindowFocus: false
  });

  const { data: gamesSessions } = useQuery({
    queryKey: ['balancedEquationGamesSessions'],
    queryFn: getGamesSessions,
    refetchOnWindowFocus: false,
    cacheTime: 0
  });

  useEffect(() => {
    if(currentGameId) {
      if (gamesSessions && !gamesSessions.some(session => session.user === user?.id && session.is_ready === true && session.game === currentGameId)) {
        let redirectUrl;
        if(shouldGuidedPagesBeDisplayed) {
          redirectUrl = '/balanced-equation/level/1/intro';
        } else {
          redirectUrl = '/balanced-equation/level/1/tutorial';
        }
        navigate(redirectUrl);
      }
    }
  }, [gamesSessions, navigate, user?.id, currentGameId, shouldGuidedPagesBeDisplayed]);

  const scenarioLevelsResponse = useQuery(scenarioLevelsQuery({scenarioId: scenarioId, level: levelNumber})) || {};
  const scenarioLevels = scenarioLevelsResponse.data || null;

  useEffect(() => {
    if (scenarioLevels?.length && isDecisionsSuccess) {
      resetBalanceSheet();
      const scenarioLevel = scenarioLevels.find(scenarioLevel => scenarioLevel.level === levelNumber);
      const decisionsMade = decisionsResponse || [];

      const {
        topOptions,
        changeTypeOptions,
        coreTypeOptions,
        transactionTypeOptions
    } = scenarioLevel.be_options.reduce((acc, item) => {
      let { name, description } = item.account_item;
      if (!description) {
          description = name;
      }

      const formattedItem = {
        id: item.id,
        order: item.order,
        accountItemId: item.account_item.id,
        name,
        description,
        type: ''
      };

      if (['Assets', 'Liabilities', 'Equity'].includes(item.account_item.name)) {
        acc.topOptions.push(formattedItem);
      } else if (['Increase', 'Decrease'].includes(item.account_item.name)) {
        formattedItem.name = name === 'Increase' ? <img src={SignPlus} alt="Plus" /> : <img src={SignMinus} alt="Minus" />;
        formattedItem.type = 'change';
        acc.changeTypeOptions.push(formattedItem);
      } else if (['Debit', 'Credit'].includes(item.account_item.name)) {
        formattedItem.type = 'transaction';
        acc.transactionTypeOptions.push(formattedItem);
      } else {
        formattedItem.type = 'core';
        acc.coreTypeOptions.push(formattedItem);
      }

        return acc;
      }, { topOptions: [], changeTypeOptions: [], coreTypeOptions: [], transactionTypeOptions: [] });

      topOptions.sort((a, b) => a.order - b.order);
      changeTypeOptions.sort((a, b) => a.order - b.order);
      coreTypeOptions.sort((a, b) => a.order - b.order);
      transactionTypeOptions.sort((a, b) => a.order - b.order);

      setTopOptions(topOptions);
      setChangeTypeOptions(changeTypeOptions);
      setCoreTypeOptions(coreTypeOptions);
      setTransactionTypeOptions(transactionTypeOptions);

      const countOptionsToBeFilled = !!changeTypeOptions.length + !!coreTypeOptions.length + !!transactionTypeOptions.length;
      setOptionsToBeFilled(countOptionsToBeFilled + 1); // +1 since we always need to have non draggable value inside drop area

      const unansweredQuestions = [];
      let allCorrectDecisionsScore = 0;

      const changeTypeIds = changeTypeOptions.map(option => option.id);
      const coreTypeIds = coreTypeOptions.map(option => option.id);
      
      scenarioLevel.be_questions
        .sort((a, b) => a.order - b.order)
        .forEach(question => {
          const relatedCorrectDecisions = decisionsMade.filter(decision => decision.question === question.id && decision.is_correct === true);
          const hasCorrectlyAnsweredDecision = relatedCorrectDecisions.some(decision => decision.question_answered_correctly === true);

          const questionScore = relatedCorrectDecisions.reduce((sum, decision) => sum + decision.score_points, 0);
          allCorrectDecisionsScore += questionScore;
          
          if (!hasCorrectlyAnsweredDecision) {
            unansweredQuestions.push({
              id: question.id,
              value: question.value,
              description: question.description
            });
          } else {
            const uniqueCorrectChangeDecisions = [];
            const uniqueChangeCombinations = new Set();

            const filteredCorrectChangeDecisions = relatedCorrectDecisions.filter(decision => 
              changeTypeIds.includes(decision.selected_option)
            );

            for (const decision of filteredCorrectChangeDecisions) {
              const combinationKey = `${decision.item_option}-${decision.selected_option}`;
              if (!uniqueChangeCombinations.has(combinationKey)) {
                uniqueCorrectChangeDecisions.push(decision);
                uniqueChangeCombinations.add(combinationKey);
              }
              if (uniqueCorrectChangeDecisions.length >= 2) break; // We only need the first two unique decisions
            }

            for (const decision of uniqueCorrectChangeDecisions) {
              const relatedItemOption = topOptions.find(topOption => topOption.accountItemId === decision.item_option);
              const relatedChangeOption = changeTypeOptions.find(changeTypeOption => changeTypeOption.id === decision.selected_option);

              const value = relatedChangeOption.description === 'Increase' ? decision.value : -decision.value;
              balanceSheetTotalChange(relatedItemOption.name, value);
            }

            const uniqueCorrectCoreDecisions = [];
            const uniqueCoreCombinations = new Set();

            const filteredCorrectCoreDecisions = relatedCorrectDecisions.filter(decision => 
              coreTypeIds.includes(decision.selected_option) && decision.selected_change
            );

            for (const decision of filteredCorrectCoreDecisions) {
              const combinationKey = `${decision.item_option}-${decision.selected_option}`;
              if (!uniqueCoreCombinations.has(combinationKey)) {
                uniqueCorrectCoreDecisions.push(decision);
                uniqueCoreCombinations.add(combinationKey);
              }
              if (uniqueCorrectCoreDecisions.length >= 2) break; // We only need the first two unique decisions
            }
            
            for (const decision of uniqueCorrectCoreDecisions) {
              const relatedItemOption = topOptions.find(topOption => topOption.accountItemId === decision.item_option);
              const relatedCoreOption = coreTypeOptions.find(coreTypeOption => coreTypeOption.id === decision.selected_option);
              const relatedChangeOption = changeTypeOptions.find(changeTypeOption => changeTypeOption.accountItemId === decision.selected_change);

              const value = relatedChangeOption?.description === 'Increase' ? decision.value : -decision.value;
              balanceSheetChildrenChange(relatedItemOption.name, { name: relatedCoreOption.name, value }, 'add');
            }
          }
        });

      setTotalScore(allCorrectDecisionsScore);

      if(unansweredQuestions.length) {
        setQuestions(unansweredQuestions);
        setActiveQuestionId(unansweredQuestions[0].id);
      } else {
        setIsPlaying(false);
        setIsModalVisible(true);
      }
    }
  }, [scenarioLevels, decisionsResponse, isDecisionsSuccess, levelNumber]);

  const balanceSheetTotalChange = (optionName, value) => {
    if (optionName === "Assets") {
      setAssets(prevAssets => prevAssets + value);
    } else if (optionName === "Liabilities") {
      setLiabilities(prevLiabilities => prevLiabilities + value);
    } else if (optionName === "Equity") {
      setEquity(prevEquity => prevEquity + value);
    }
  }
  
  const balanceSheetChildrenChange = (optionName, item, action, canRemove = false) => {
    if (optionName === "Assets") {
      if (action === 'add') {
        const existingItemIndex = assetsChildrenRef.current.findIndex(child => child.name === item.name);
  
        if (existingItemIndex !== -1) {
          const updatedChildren = [...assetsChildrenRef.current];
          updatedChildren[existingItemIndex].value += item.value;
          if (updatedChildren[existingItemIndex].value === 0 && canRemove) {
            updatedChildren.splice(existingItemIndex, 1); // Remove the item if value is 0
          }
          setAssetsChildren(updatedChildren);
          assetsChildrenRef.current = updatedChildren;  // Update the ref
        } else {
          const newChildren = [...assetsChildrenRef.current, item];
          setAssetsChildren(newChildren);
          assetsChildrenRef.current = newChildren;  // Update the ref
        }
      } else if (action === 'remove') {
        const newChildren = assetsChildrenRef.current.filter(child => child.name !== item.name);
        setAssetsChildren(newChildren);
        assetsChildrenRef.current = newChildren;  // Update the ref
      }
    } else if (optionName === "Liabilities") {
      if (action === 'add') {
        const existingItemIndex = liabilitiesChildrenRef.current.findIndex(child => child.name === item.name);
  
        if (existingItemIndex !== -1) {
          const updatedChildren = [...liabilitiesChildrenRef.current];
          updatedChildren[existingItemIndex].value += item.value;
          if (updatedChildren[existingItemIndex].value === 0 && canRemove) {
            updatedChildren.splice(existingItemIndex, 1); // Remove the item if value is 0
          }
          setLiabilitiesChildren(updatedChildren);
          liabilitiesChildrenRef.current = updatedChildren;  // Update the ref
        } else {
          const newChildren = [...liabilitiesChildrenRef.current, item];
          setLiabilitiesChildren(newChildren);
          liabilitiesChildrenRef.current = newChildren;  // Update the ref
        }
      } else if (action === 'remove') {
        const newChildren = liabilitiesChildrenRef.current.filter(child => child.name !== item.name);
        setLiabilitiesChildren(newChildren);
        liabilitiesChildrenRef.current = newChildren;  // Update the ref
      }
    } else if (optionName === "Equity") {
      if (action === 'add') {
        const existingItemIndex = equityChildrenRef.current.findIndex(child => child.name === item.name);
  
        if (existingItemIndex !== -1) {
          const updatedChildren = [...equityChildrenRef.current];
          updatedChildren[existingItemIndex].value += item.value;
          if (updatedChildren[existingItemIndex].value === 0 && canRemove) {
            updatedChildren.splice(existingItemIndex, 1); // Remove the item if value is 0
          }
          setEquityChildren(updatedChildren);
          equityChildrenRef.current = updatedChildren;  // Update the ref
        } else {
          const newChildren = [...equityChildrenRef.current, item];
          setEquityChildren(newChildren);
          equityChildrenRef.current = newChildren;  // Update the ref
        }
      } else if (action === 'remove') {
        const newChildren = equityChildrenRef.current.filter(child => child.name !== item.name);
        setEquityChildren(newChildren);
        equityChildrenRef.current = newChildren;  // Update the ref
      }
    }
  };
  
  
  

  const [filledRecords, setFilledRecords] = useState([]);

  const [optionsToBeFilled, setOptionsToBeFilled] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const matchingRecords = filledRecords.filter(record => record.droppedItemsCount === optionsToBeFilled);
    const recordsWithDroppedItems = filledRecords.filter(record => record.droppedItemsCount > 0);

    if (
      matchingRecords.length !== 2 
        || recordsWithDroppedItems.length !== 2
        || (
          matchingRecords[0].optionAccountItemId === matchingRecords[1].optionAccountItemId
            && (
              matchingRecords[0].changeId === matchingRecords[1].changeId
                || (matchingRecords[0].coreId && matchingRecords[1].coreId && (matchingRecords[0].coreId === matchingRecords[1].coreId))
                || (matchingRecords[0].transactionId && matchingRecords[1].transactionId && (matchingRecords[0].transactionId === matchingRecords[1].transactionId))
            )
      )
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [filledRecords, optionsToBeFilled]);

  const onDropAreaChange = ({
    id,
    droppedItemsCount,
    box,
    optionAccountItemId,
    optionName,
    changeId,
    changeAccountItemId,
    changeDescription,
    coreId,
    coreName,
    transactionId,
    transactionAccountItemId,
    transactionName
  }) => {
    setFilledRecords(prevRecords => {
      const recordExists = prevRecords.some(record => record.id === id);
      let updatedRecords;
  
      if(recordExists) {
        updatedRecords = prevRecords.map(record => {
          if(record.id === id) {
            const updatedRecord = { ...record, droppedItemsCount };
            if(changeId) {
              updatedRecord.changeId = changeId;
              updatedRecord.changeAccountItemId = changeAccountItemId;
              updatedRecord.changeDescription = changeDescription;
            } else if(changeId === 0) {
              updatedRecord.changeId = null;
              updatedRecord.changeAccountItemId = null;
              updatedRecord.changeDescription = null;
            }
            if(coreId) {
              updatedRecord.coreId = coreId;
              updatedRecord.coreName = coreName;
            } else if(coreId === 0) {
              updatedRecord.coreId = null;
              updatedRecord.coreName = null;
            }
            if(transactionId){
              updatedRecord.transactionId = transactionId;
              updatedRecord.transactionAccountItemId = transactionAccountItemId;
              updatedRecord.transactionName = transactionName;
            } else if(transactionId === 0) {
              updatedRecord.transactionId = null;
              updatedRecord.transactionAccountItemId = null;
              updatedRecord.transactionName = null;
            }
            return updatedRecord;
          }
          return record;
        });
      } else {
        const recordItem = {
          id,
          droppedItemsCount,
          box,
          optionAccountItemId,
          optionName,
          changeId,
          changeAccountItemId,
          changeDescription,
          coreId,
          coreName,
          transactionId,
          transactionAccountItemId,
          transactionName
        };
        updatedRecords = [...prevRecords, recordItem];
      }
  
      return updatedRecords;
    });
  };

  const decisionMutation = useMutation({
    mutationFn: (data) => makeDecision(data)
  });
  
  const updateDecisionMutation = useMutation({
    mutationFn: (data) => updateDecision(data)
  });
  
  const [resetKey, setResetKey] = useState(0);
  const [incorrectDecisions, setIncorrectDecisions] = useState([]);

  const handleNextButtonClick = () => {
    setResetKey(prevKey => prevKey + 1);
    setFilledRecords([]);
    setIsSubmitButtonShown(true);
    setIsExplanationVisible(false);
    setIncorrectDecisions([]);

    if(questions.length > 1) {
      setTimeFromAppear(timeRef.current);
      setActiveQuestionId(prevActiveQuestionId => {
        const currentIndex = questions.findIndex(question => question.id === prevActiveQuestionId);
        
        const nextIndex = (currentIndex === questions.length - 1) ? 0 : currentIndex + 1;
        
        return questions[nextIndex].id;
      });

      if(questionAnsweredCorrectly) {
        setQuestions(prevQuestions => prevQuestions.filter(question => question.id !== questionAnsweredCorrectly));
        setQuestionAnsweredCorrectly(0);
      }
    } else {
      if(questionAnsweredCorrectly) {
        setIsPlaying(false);
        setIsModalVisible(true);
      }
      
    }

    setIsItemsDisabled(false);
  }

  const handleSubmitButtonClick = async () => {
    setIsLoading(true);
    const latestFilledRecords = filledRecords.filter(filledRecord => filledRecord.droppedItemsCount);
    const activeQuestion = questions.find(question => question.id === activeQuestionId);
  
    const timeElapsed = timeRef.current - timeFromAppear;
  
    const decisionResults = [];
    const timestamp = new Date().toISOString();
    const groupId = timestamp.replace(/\D/g, '');

    try {
      for (const record of latestFilledRecords) {
        if (record.changeId) {
          const result = await decisionMutation.mutateAsync({
            user: user?.id,
            game: currentGameId,
            timeElapsed,
            question: activeQuestion.id,
            box: record.box,
            itemOption: record.optionAccountItemId,
            selectedOption: record.changeId,
            timestamp,
            groupId
          });
          decisionResults.push({
            id: record.changeId,
            decisionId: result.id,
            isCorrect: result.is_correct,
            type: 'change',
            description: record.changeDescription,
            optionAccountItemId: record.optionAccountItemId,
            optionName: record.optionName,
            isSecondDropArea: record.box === 'Bottom',
            questionId: activeQuestion.id,
            score: result.score_points,
            name: record.changeDescription,
            value: result.value
          });
        }
  
        if (record.coreId) {
          const result = await decisionMutation.mutateAsync({
            user: user?.id,
            game: currentGameId,
            timeElapsed,
            question: activeQuestion.id,
            box: record.box,
            itemOption: record.optionAccountItemId,
            selectedChange: record.changeAccountItemId,
            selectedOption: record.coreId,
            timestamp,
            groupId
          });
          decisionResults.push({
            id: record.coreId,
            decisionId: result.id,
            isCorrect: result.is_correct,
            type: 'core',
            optionAccountItemId: record.optionAccountItemId,
            optionName: record.optionName,
            isSecondDropArea: record.box === 'Bottom',
            questionId: activeQuestion.id,
            score: result.score_points,
            name: record.coreName,
            value: result.value
          });
        }
  
        if (record.transactionId) {
          const result = await decisionMutation.mutateAsync({
            user: user?.id,
            game: currentGameId,
            timeElapsed,
            question: activeQuestion.id,
            box: record.box,
            itemOption: record.optionAccountItemId,
            selectedOption: record.transactionId,
            timestamp,
            groupId
          });
          decisionResults.push({
            id: record.transactionId,
            decisionId: result.id,
            isCorrect: result.is_correct,
            type: 'transaction',
            optionAccountItemId: record.optionAccountItemId,
            optionName: record.optionName,
            isSecondDropArea: record.box === 'Bottom',
            questionId: activeQuestion.id,
            score: result.score_points,
            name: record.transactionName,
            value: result.value
          });
        }
      }
      
    } catch (error) {
      console.log('Some mutations failed:', error);
    } finally {
      const correctResults = decisionResults.filter(decisionResult => decisionResult.isCorrect);

      correctResults.filter(correctResult => correctResult.score).forEach(correctResult => {
        setTotalScore(prevTotalScore => prevTotalScore + correctResult.score);
      });

      const allResultsCorrect = correctResults.length === decisionResults.length;

      if (allResultsCorrect) {
        answerCorrectEffect.current.play().catch(e => console.log('Error playing answer correct sound:', e));
        
        updateDecisionMutation.mutate({id: decisionResults[0].decisionId});
        
        setQuestionAnsweredCorrectly(decisionResults[0].questionId);
        setAnswerStatus('correct');

        let firstUpdatedOptionName = '';
        switch(latestFilledRecords[0].optionName) {
          case 'Assets':
            firstUpdatedOptionName = 'an "Asset"';
            break;
          case 'Liabilities':
            firstUpdatedOptionName = 'a "Liability"';
            break;
          case 'Equity':
            firstUpdatedOptionName = 'an "Equity"';
            break;
          default:
            firstUpdatedOptionName = 'an "Asset"';
            break;
        }
        
        let secondUpdatedOptionName = '';
        switch(latestFilledRecords[1].optionName) {
          case 'Assets':
            secondUpdatedOptionName = 'an "Asset"';
            break;
          case 'Liabilities':
            secondUpdatedOptionName = 'a "Liability"';
            break;
          case 'Equity':
            secondUpdatedOptionName = 'an "Equity"';
            break;
          default:
            secondUpdatedOptionName = 'an "Asset"';
            break;
        }

        let explanation = '';
        switch(decisionResults.length) {
          case 2:
            explanation = `${firstUpdatedOptionName[0].toUpperCase() + firstUpdatedOptionName.slice(1)} should "${latestFilledRecords[0].changeDescription}" and ${secondUpdatedOptionName} should "${latestFilledRecords[1].changeDescription}". Click "Next" to continue.`;
            break;
          case 4:
            explanation = `${firstUpdatedOptionName[0].toUpperCase() + firstUpdatedOptionName.slice(1)} account, "${latestFilledRecords[0].coreName}", should "${latestFilledRecords[0].changeDescription}" and ${secondUpdatedOptionName} account, "${latestFilledRecords[1].coreName}", should "${latestFilledRecords[1].changeDescription}". Click "Next" to continue.`;
            break;
          case 6:
            explanation = `"${latestFilledRecords[0].coreName}" should be "${latestFilledRecords[0].transactionName}ed" since it’s ${firstUpdatedOptionName} that "${latestFilledRecords[0].changeDescription}d". "${latestFilledRecords[1].coreName}" should be "${latestFilledRecords[1].transactionName}ed" since it’s ${secondUpdatedOptionName} that "${latestFilledRecords[1].changeDescription}d". Click "Next" to continue.`;
            break;
          default:
            explanation = `${firstUpdatedOptionName[0].toUpperCase() + firstUpdatedOptionName.slice(1)} should "${latestFilledRecords[0].changeDescription}" and ${secondUpdatedOptionName} should "${latestFilledRecords[1].changeDescription}". Click "Next" to continue.`;
            break;
        }
        setExplanationContent(explanation);
        setIsExplanationVisible(true);
      } else {
        answerWrongEffect.current.play().catch(e => console.log('Error playing answer wrong sound:', e));

        const currentIncorrectDecisions = decisionResults.filter(decisionResult => !decisionResult.isCorrect);
        setIncorrectDecisions(currentIncorrectDecisions);

        let coreAdjustmentValue;
        decisionResults.forEach(decision => {
          if (decision.type === 'change') {
            const lowerCaseOptionName = decision.optionName.toLowerCase();
            const adjustmentValue = decision.description === 'increase' ? -activeQuestion.value : activeQuestion.value;
            coreAdjustmentValue = adjustmentValue;
      
            switch (lowerCaseOptionName) {
              case 'assets':
                setAssets(prevAssets => prevAssets + adjustmentValue);
                break;
              case 'liabilities':
                setLiabilities(prevLiabilities => prevLiabilities + adjustmentValue);
                break;
              case 'equity':
                setEquity(prevEquity => prevEquity + adjustmentValue);
                break;
              default:
                console.error(`Unknown option name: ${lowerCaseOptionName}`);
            }
          } else if (decision.type === 'core') {
            balanceSheetChildrenChange(decision.optionName, {name: decision.name, value: coreAdjustmentValue}, 'add', true);
          }
        });

        setAnswerStatus('incorrect');
        setExplanationContent('Some or all of your decisions are not correct. Don’t worry, you’ll have another chance to answer this question. Click "Next" to continue.');
        setIsExplanationVisible(true);
      }

      setIsSubmitButtonShown(false);
      setIsLoading(false);
    }

    setIsItemsDisabled(true);
  };

  const [isLoading, setIsLoading] = useState(false);

  const activeQuestion = questions.find(question => question.id === activeQuestionId);

  const handleViewSummaryButtonClick = () => {
    let redirectUrl = '';

    if(levelNumber === 3) {
      redirectUrl = '/balanced-equation/badge';
    } else {
      if(shouldGuidedPagesBeDisplayed) {
        redirectUrl = `/balanced-equation/level/${levelNumber + 1}/intro/`;
      } else {
        setIsModalVisible(false);
        redirectUrl = `/balanced-equation/level/${levelNumber + 1}/tutorial`;
      }
    }
    navigate(redirectUrl);
  }

  const [isItemsDisabled, setIsItemsDisabled] = useState(false);

  const [isSubmitHintShown, setIsSubmitHintShown] = useState(false);
  const [submitButtonHintContent, setSubmitButtonHintContent] = useState(<span></span>);

  const handleSubmitButtonMouseEnter = () => {
    if(isSubmitButtonShown && isButtonDisabled) {
      let submitMessage = '';

      const recordsWithDroppedItems = filledRecords.filter(record => record.droppedItemsCount > 0);

      if(recordsWithDroppedItems.length < 2) {
        submitMessage = 'To submit, there must be decisions in 2 records.';
      } else if(recordsWithDroppedItems.length === 2) {
        const bothHaveChangeId = recordsWithDroppedItems.every(record => record.changeId);
        if(!bothHaveChangeId) {
          submitMessage = 'Increase or decrease needs to be in 2 records.';
        } else {
          const bothHaveCoreId = recordsWithDroppedItems.every(record => record.coreId);
          if(!bothHaveCoreId && levelNumber >= 2) {
            submitMessage = 'An account needs to be in 2 records.';
          } else {
            const bothHaveTransactionId = recordsWithDroppedItems.every(record => record.transactionId);
            if(!bothHaveTransactionId  && levelNumber >= 3) {
              submitMessage = 'A debit or a credit needs to be in 2 records.';
            } else {
              if(recordsWithDroppedItems[0].changeId === recordsWithDroppedItems[1].changeId) {
                submitMessage = 'An increase or decrease decision is missing.';
              } else if(recordsWithDroppedItems[0].coreId === recordsWithDroppedItems[1].coreId) {
                submitMessage = 'An account decision is missing.';
              } else if(recordsWithDroppedItems[0].transactionId === recordsWithDroppedItems[1].transactionId) {
                submitMessage = 'A debit or credit decision is missing.';
              }
            }
          }
        }
      } else {
        submitMessage = 'There are too many records; decisions should be in no more than 2 records.';
      }

      setSubmitButtonHintContent(submitMessage);
      setIsSubmitHintShown(true);
    }
  }

  const handleSubmitButtonMouseLeave = () => {
    setIsSubmitHintShown(false);
  }

  const isNextLevelButtonVisible = scenarioLevels?.length > levelNumber;

  return(
    <Layout
      backgroundImage={BackgroundImage}
      isHeaderVisible={true}
      isFooterVisible={true}
      time={time}
      totalScore={totalScore}
      scoreLabel="Points"
      isExplanationVisible={isExplanationVisible}
      explanationName="Bailey"
      isAvatarVisible={true}
      answerStatus={answerStatus}
      explanationContent={explanationContent}
    >
      <TransactionRecords 
        transactionText={activeQuestion?.description}
        value={activeQuestion?.value}
        topOptions={topOptions}
        changeTypeOptions={changeTypeOptions}
        coreTypeOptions={coreTypeOptions}
        transactionTypeOptions={transactionTypeOptions}
        onChangeItemDrop={balanceSheetTotalChange}
        onCoreItemDrop={balanceSheetChildrenChange}
        onDropAreaChange={onDropAreaChange}
        isSubmitButtonShown={isSubmitButtonShown}
        isSubmitHintShown={isSubmitHintShown}
        submitHintContent={submitButtonHintContent}
        onSubmitButtonClick={handleSubmitButtonClick}
        onSubmitButtonMouseEnter={handleSubmitButtonMouseEnter}
        onSubmitButtonMouseLeave={handleSubmitButtonMouseLeave}
        onNextButtonClick={handleNextButtonClick}
        isButtonLoading={isLoading}
        isButtonDisabled={isButtonDisabled}
        resetKey={resetKey}
        incorrectDecisions={incorrectDecisions}
        isItemsDisabled={isItemsDisabled}
      />
      <BalanceSheet
        assets={assets}
        liabilities={liabilities}
        equity={equity}
        assetsChildren={assetsChildren}
        liabilitiesChildren={liabilitiesChildren}
        equityChildren={equityChildren}
      />
      {isModalVisible && (
        <Modal
          content={
            <>
              <div className="modal-center vertical">
                <div><span>Well done!</span></div>
                {
                  isNextLevelButtonVisible &&
                    <button onClick={handleViewSummaryButtonClick} className="button-blue button-view-summary">Next level</button>
                }
              </div>
            </>
          }
          isModalSmall={true}
        />
      )}
    </Layout>
  );
}
