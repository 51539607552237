import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import useAuth from '../../auth/useAuth';

import Layout from '../UI/introGameLayout/layout';

import BackgroundImage from './images/summary-background.jpg';

import {
  gamesQuery,
  scoresStatsQuery,
  scenarioLevelsQuery
} from './api';

import {
  popupMessagesAccuracy,
  popupMessagesAccuracyItems,
  popupMessagesAccuracyGeneralTexts
} from './data';

import Analysis from '../UI/game/analysis';
import PopupMessages from '../UI/introGameLayout/popupMessages';

import './styles.css';

export default function NormalBalanceAccuracy() {
  const params = useParams();
  const levelNumber = Number(params.levelNumber);

  const navigate = useNavigate();
  
  const { 
    getUser,
    getGuidedPlay
  } = useAuth();

  const user = getUser();
  const username = user?.alias_name 
    ? user?.alias_name 
    : user?.first_name 
      ? user?.first_name
      : user?.username
        ? user?.username 
        : '';
  
  const [currentGameId, setCurrentGameId] = useState(0);
  const [scenarioId, setScenarioId] = useState(0);
  const { data: games } = useQuery(gamesQuery()) || {};

  useEffect(() => {
    if(games) {
      const game = games.find(game => game.game_type.includes('Normal Balance'));

      if(game && game.scenario) {
        setCurrentGameId(game.id);
        setScenarioId(game.scenario);
      }
    }
  }, [games]);

  const shouldGuidedPagesBeDisplayed = getGuidedPlay();

  const [data, setData] = useState([]);
  const [rank, setRank] = useState('');
  const [accuracy, setAccuracy] = useState('');
  const [avarageTime, setAvarageTime] = useState('');
  const [levelsCount, setLevelsCount] = useState(0);

  const { data: scoresStats } = useQuery(scoresStatsQuery({ gameId: currentGameId }));
  const { data: scenarioLevels } = useQuery(scenarioLevelsQuery({scenarioId}));

  useEffect(() => {
    if(scoresStats && scenarioLevels) {
      let scenarioLevelsCount = scenarioLevels.length;
      setLevelsCount(scenarioLevelsCount);

      let scoresStatsData;
      
      if (!shouldGuidedPagesBeDisplayed && levelNumber === scenarioLevelsCount) {
        const {
          totalUserAccuracy,
          totalUserTime,
          totalAccuracy,
          totalMedianAccuracy,
          totalShortestTime,
          totalMedianTime,
          totalLongestTime
        } = scoresStats.reduce(
          (acc, stat) => {
            acc.totalUserAccuracy += stat.user_accuracy;
            acc.totalUserTime += stat.user_average_time;
            acc.totalAccuracy += stat.average_accuracy;
            acc.totalMedianAccuracy += stat.median_accuracy;
            acc.totalShortestTime += stat.shortest_average_time;
            acc.totalMedianTime += stat.median_average_time;
            acc.totalLongestTime += stat.longest_average_time;
            return acc;
          },
          {
            totalUserAccuracy: 0,
            totalUserTime: 0,
            totalAccuracy: 0,
            totalMedianAccuracy: 0,
            totalShortestTime: 0,
            totalMedianTime: 0,
            totalLongestTime: 0
          }
        );
      
        scoresStatsData = {
          user_accuracy: totalUserAccuracy / scenarioLevelsCount,
          user_average_time: totalUserTime / scenarioLevelsCount,
          average_accuracy: totalAccuracy / scenarioLevelsCount,
          median_accuracy: totalMedianAccuracy / scenarioLevelsCount,
          shortest_average_time: totalShortestTime / scenarioLevelsCount,
          median_average_time: totalMedianTime / scenarioLevelsCount,
          longest_average_time: totalLongestTime / scenarioLevelsCount
        };
      } else {
        scoresStatsData = scoresStats.find(scoresStat => scoresStat.level === levelNumber);
      }

      setRank(scoresStatsData?.rank || 'Newbie');
      setAccuracy(`${parseFloat((scoresStatsData?.user_accuracy * 100).toFixed(2)).toString()}%`);
      setAvarageTime(parseFloat(scoresStatsData?.user_average_time?.toFixed(2)).toString());

      const firstBarsData = [
        { title: username, score: scoresStatsData?.user_accuracy * 100, color: '#F9C151' },
        { title: 'Mean', score: scoresStatsData?.average_accuracy * 100 },
        { title: 'Median', score: scoresStatsData?.median_accuracy * 100 },
      ];
      const secondBarsData = [
        { title: username, score: scoresStatsData?.user_average_time, color: '#F9C151' },
        { title: 'Mastery', score: 2, color: '#5C91F5' },
        { title: 'Shortest Time', score: scoresStatsData?.shortest_average_time },
        { title: 'Median', score: scoresStatsData?.median_average_time },
        { title: 'Longest Time', score: scoresStatsData?.longest_average_time },
      ];
      const data = [
        {
          title: 'Accuracy',
          bars: firstBarsData,
          barsLabel: '%'
        },
        {
          title: 'Average Time',
          subtitle: 'In Seconds',
          bars: secondBarsData
        }
      ]
      setData(data);
    }
  }, [username, scoresStats, levelNumber, scenarioLevels, shouldGuidedPagesBeDisplayed]);

  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (games && scenarioLevels && scoresStats) {
      setDataLoaded(true);
    }
  }, [games, scenarioLevels, scoresStats]);

  let popupMessages = rank ? popupMessagesAccuracy[rank] : popupMessagesAccuracy['Newbie'];

  const items = popupMessagesAccuracyItems[levelNumber - 1];
  const generalTexts = popupMessagesAccuracyGeneralTexts[levelNumber - 1];

  if(popupMessages) {
    popupMessages = popupMessages.map(message => ({
      ...message,
      text: message.text.replace('{items}', items)
        .replace('{accuracy}', accuracy)
        .replace('{avarageTime}', avarageTime)
        .replace('{name}', username)
        .replace('{generalTexts}', generalTexts)
    }));
  }

  const handlePopupMessagesNextButtonClick = () => {
    let redirectUrl;
    if(rank === 'Master') {
      if(shouldGuidedPagesBeDisplayed) {
        redirectUrl = `/normal/level/${levelNumber + 1}/intro`;
      } else {
        if(levelNumber === 4) {
          redirectUrl = `/normal/level/5/tutorial`;
        } else {
          redirectUrl = `/normal/level/${levelNumber + 1}/`;
        }
      }
    } else {
      redirectUrl = `/normal/level/${levelNumber}/most-challenging`;
    }

    navigate(redirectUrl);
  }

  const analysisHeading = !shouldGuidedPagesBeDisplayed && levelNumber === levelsCount ? 'Analysis' : `Level ${levelNumber} Analysis`;
  console.log('popupMessages', popupMessages);

  return(
    <Layout
      backgroundImage={BackgroundImage}
      isBackgroundBlurred={false}
      isHeaderVisible={true}
      isFooterVisible={false}
    >
      {
        dataLoaded && popupMessages?.length > 0 &&
          <PopupMessages
            messages={popupMessages}
            buttonText="Next"
            onButtonClick={(levelNumber < levelsCount || rank !== 'Master') ? handlePopupMessagesNextButtonClick : undefined}
            messageSize="small"
          />
      }

      <Analysis
        heading={analysisHeading}
        data={data}
      />
    </Layout>
  );
}
