import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import useAuth from '../../auth/useAuth';

import Layout from '../UI/introGameLayout/layout';

import BackgroundImage from './images/summary-background.jpg';

import { 
  scoresByQuestionQuery,
  scenarioLevelsQuery,
  gamesQuery
} from './api';

import {
  popupMessagesMostChallenging,
  popupMessagesAccuracyItems,
  popupMessagesAccuracyGeneralTexts
} from './data';

import Analysis from '../UI/game/analysis';
import PopupMessages from '../UI/introGameLayout/popupMessages';

import './styles.css';

export default function GameMostChallenging() {
  const params = useParams();
  const levelNumber = Number(params.levelNumber);

  const navigate = useNavigate();
  
  const { 
    getUser,
    getGuidedPlay
  } = useAuth();

  const user = getUser();
  const username = user?.alias_name 
    ? user?.alias_name 
    : user?.first_name 
      ? user?.first_name
      : user?.username
        ? user?.username 
        : '';
  
  const [currentGameId, setCurrentGameId] = useState(0);
  const [scenarioId, setScenarioId] = useState(0);
  
  const shouldGuidedPagesBeDisplayed = getGuidedPlay();
  
  const [data, setData] = useState([]);
  const [levelsCount, setLevelsCount] = useState(0);

  const { data: scenarioLevels } = useQuery(scenarioLevelsQuery({scenarioId}));
  const { data: scoresByQuestion } = useQuery(scoresByQuestionQuery({ gameId: currentGameId }));

  const { data: games } = useQuery(gamesQuery()) || {};

  useEffect(() => {
    if(games) {
      const game = games.find(game => game.game_type.includes('Account Classification'));

      if(game && game.scenario) {
        setCurrentGameId(game.id);
        setScenarioId(game.scenario);
      }
    }
  }, [games]);

  useEffect(() => {
    if(scoresByQuestion && scenarioLevels) {
      let scenarioLevelsCount = scenarioLevels.length;
      setLevelsCount(scenarioLevelsCount);

      const scoresByQuestionData = scoresByQuestion
        .filter(scoreByQuestion => 
          (!shouldGuidedPagesBeDisplayed && levelNumber === scenarioLevelsCount) 
            ? scoreByQuestion.user === user.id
            : scoreByQuestion.level === levelNumber && scoreByQuestion.user === user.id
        )
        .sort((a, b) => a.total_points - b.total_points)
        .slice(0, 5);
      
      const barsData = [
        { title: 'Mastery', score: 90, color: '#70A1FF' },
        ...scoresByQuestionData.map(scoreByQuestion => ({
          title: scoreByQuestion.question_name,
          score: scoreByQuestion.total_points
        }))
      ];
      const data = [
        {
          title: 'Accounts',
          subtitle: 'Max points = 100',
          bars: barsData
        }
      ]
      setData(data);
    }
  }, [username, scoresByQuestion, levelNumber, scenarioLevels, user.id]);

  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (games && scoresByQuestion && scenarioLevels) {
      setDataLoaded(true);
    }
  }, [games, scoresByQuestion, scenarioLevels]);

  const items = popupMessagesAccuracyItems[levelNumber - 1];
  const generalTexts = popupMessagesAccuracyGeneralTexts[levelNumber - 1];

  const popupMessages = popupMessagesMostChallenging.map(message => ({
    ...message,
    text: message.text.replace('{items}', items)
      .replace('{name}', username)
      .replace('{generalTexts}', generalTexts)
  }));

  const handlePopupMessagesNextButtonClick = () => {
    let redirectUrl;

    if(levelNumber < levelsCount) {
      if(shouldGuidedPagesBeDisplayed) {
        redirectUrl = `/classification/level/${levelNumber + 1}/intro`;
      } else {
        if(levelNumber === 3) {
          redirectUrl = `/classification/level/4/tutorial`;
        } else {
          redirectUrl = `/classification/level/${levelNumber + 1}/`;
        }
      }
    } else {
      redirectUrl = `/classification/feedback`;
    }

    navigate(redirectUrl);
  }

  const analysisHeading = !shouldGuidedPagesBeDisplayed && levelNumber === levelsCount ? 'Analysis' : `Level ${levelNumber} Analysis`;

  return(
    <Layout
      backgroundImage={BackgroundImage}
      isBackgroundBlurred={false}
      isHeaderVisible={true}
      isFooterVisible={false}
    >
      {
        dataLoaded && popupMessages?.length > 0 && (
          <PopupMessages
            messages={popupMessages}
            buttonText="Next"
            onButtonClick={handlePopupMessagesNextButtonClick}
            messageSize="small"
          />
        )
      }

      <Analysis
        heading={analysisHeading}
        data={data}
      />
    </Layout>
  );
}
